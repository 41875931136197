import Vue from 'vue'
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const static_text = {

    'en': {
        "login": {
            secret_missing: 'Access key missing in URL. Add "?secret=access_key" at the end of URL.',
            invalid_secret: 'You entered an invalid access key, try again.',
        },
        "overlay": {
            help: 'Something went wrong, please notify any Study lady you will find.',
        },

        "start": {
            ticket: 'I want a ticket',
            waiting_for_study_dep: "Check-in waiting at the Study Department",
            if_you_didnt_get: "If you did not manage to book your appointment via the My MENDELU mobile app, create an appointment ticket through the calling system.",
        },

        "identity": {
            choose_type_of_study: "Select the type of study",
            weekly: "I study combined form",
            daily: "I study full-time form",
            other: "Student mobilities"
        },

        "go_cancel_arrow": {
            cancel: "Cancel",
        },

        "study_field": {
            choose_studied_field: "Select studied program",
            Master: 'Master',
            Bachelor: 'Bachelor',
        },

        "study_category": {
            select_category_demands: "Select a request category",
        },

        "study_requirement": {
            select_concrete_demand: "Select a specific request",
        },

        "study_requirement_recap": {
            chosen_demands: "Selected requirements",
            another_demand: "Add another request",
            continue: "Continue"
        },

        "ticket": {
            your_ticket: "Your ticket",
            queue_number: "ticket number",
            takes_care_off_p1: "Your ",
            takes_care_off_p2: " request will be dealt by",
            close: "Close",
            ticket_time: "Approximate ticketing time:",
            qr_code: "You reservation has been created. If you want to save the reservation in the mobile application My MENDELU, open the application, tap on + and scan the QR code. See the poster above the touch panel for instructions on how to install the app.",
        },

        "end": {
            goodbye: "Goodbye",
            we_hope: "We hope that your request will be resolved successfully.",
        },

        "closed": {
            unfortunately: "We are really sorry,",
            description: "But we are closed.",
            opening_hours: "Today's opening hours are",
        },

        "closed_pause": {
            unfortunately: "We are really sorry",
            description: "But we have a pause.",
            pause_ends_at: "The pause will last until ",
        },

        "error": {
            unfortunately: "We are really sorry",
            description: "But it is not possible to make a reservation at this time.",
        },

        "no_assistant": {
            we_are_sorry: "Unfortunately, there is currently no study assistant available for your field.",
            unfortunately: "We apologize for the inconvenience."
        },

        "student_identification": {
            identification: "Student identification",
            info: "To generate a ticket, enter your UIS login name in the form xname.",
            generate: "Generate a ticket",
            loginName: "UIS Login",
            infoErr: "The entered name was not found, check it"
        },
    },

    'cz': {
        "login": {
            secret_missing: 'V URL chybí přístupový klíč. Přidejte "?secret=pristupovy_klic" na konec URL.',
            invalid_secret: 'Zadali jste nesprávný přístupový klíč, zkuste to znovu.',
        },
        "overlay": {
            help: 'Došlo k chybě. Upozorněte svoji studijní referentku.',
        },

        "start": {
            ticket: 'Chci pořadový lístek',
            waiting_for_study_dep: "Vyřízení požadavků na studijním oddělení",
            if_you_didnt_get: "Pokud nemáte rezervovanou schůzku přes mobilní aplikaci Moje MENDELU, vytvořte si pořadový lístek prostřednictvím tohoto systému.",
        },

        "identity": {
            choose_type_of_study: "Vyberte typ studia",
            weekly: "Kombinované studium",
            daily: "Prezenční studium",
            other: "Zahraniční mobility"
        },

        "go_cancel_arrow": {
            cancel: "Zrušit",
        },

        "study_field": {
            choose_studied_field: "Vyberte studovaný program",
            Master: 'Magisterský',
            Bachelor: 'Bakalářský',
        },

        "study_category": {
            select_category_demands: "Vyberte kategorii požadavků",
        },

        "study_requirement": {
            select_concrete_demand: "Vyberte konkrétní požadavek",
        },

        "study_requirement_recap": {
            chosen_demands: "Zvolené požadavky",
            add_demand: 'Přidat požadavek',
            another_demand: "Přidat další požadavek",
            continue: "Pokračovat"
        },

        "ticket": {
            your_ticket: "Váš lístek",
            queue_number: "pořadové číslo",
            takes_care_off_p1: "O Váš ",
            takes_care_off_p2: " požadavek se postará:",
            close: "Zavřít",
            ticket_time: "Přibližný čas odbavení:",
            qr_code: "Vaše rezervace byla vytvořena. Pokud chcete rezervaci uložit do mobilní aplikace Moje MENDELU, otevřete aplikaci, klepněte na + a naskenujte QR kód. Návod k instalaci aplikace najdete na plakátu nad dotykovým panelem.",
        },

        "end": {
            goodbye: "Nashledanou",
            we_hope: "Doufáme, že se Váš požadavek úspěšně vyřeší.",
        },

        "closed": {
            unfortunately: "Omlouváme se,",
            description: "ale aktuálně máme zavřeno.",
            opening_hours: "Dnešní otevírací hodiny jsou "
        },

        "closed_pause": {
            unfortunately: "Omlouváme se,",
            description: "ale aktuálně máme pauzu.",
            pause_ends_at: "Pauza končí v ",
        },

        "error": {
            unfortunately: "Omlouváme se",
            description: "Ale v tuto chvíli není možné vytvořit rezervaci.",
        },

        "no_assistant": {
            we_are_sorry: "Bohužel v tuto chvíli není dostupná žádná studijní referentka pro váš obor.",
            unfortunately: "Omlouváme se za komplikaci."
        },
        "student_identification": {
            identification: "Identifikace studenta",
            info: "Pro vygenerování lístku zadejte svoje přihlašovací jméno z UIS ve tvaru xjméno.",
            generate: "Vygenerovat lístek",
            loginName: "UIS Login",
            infoErr: "Zadané jméno nebylo nalezeno, zkontrolujte ho"


        },


    }

};

const i18n = new VueI18n({
    locale: 'cz', // set locale
    fallbackLocale: 'cz', // set fallback locale
    messages: static_text, // set locale messages
});

export default i18n;
